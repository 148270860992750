import { Link } from "gatsby"
import React from "react"

import Layout from "../components/center-layout"
import SEO from "../components/seo"

/**
 * Page component.
 */
export default class IndexPage extends React.Component {

	/**
	 * Renders the component.
	 */
	render(): JSX.Element {
		return (
			<Layout>
				<SEO title="Home" />
				<h1>Coming Soon to a Site Near You...</h1>
				<ul className="noListItem">
					<li>
						<Link to="/robo-voice">Robo-Voice</Link>
					</li>
					<li>
						<Link to="/table-service">Table-Service</Link>
					</li>
					<li>
						<Link to="/complexicon">Complexicon</Link>
					</li>
				</ul>
			</Layout>
		)
	}
}
